/* starts, : Processor Card Connect */

.card_connect {
  iframe {
    height: 17px;
  }
  &.form-group {
    .form-control {
      padding-top: 8px;
      padding-bottom: 6px;
      line-height: 1;
      .cc-number {
        //min-width: 182px;
      }
      .cc-exp,
      .cc-cvc {
        max-width: 50px;
        padding: 0;
        input {
          max-width: 100%;
          background-color: transparent;
          border: 0;
          outline: none;
          font-size: 13px;
          color: #464E5F;
          &::-moz-placeholder {
            color: #b5b5c3;
            opacity: 1;
          }
          &:-ms-input-placeholder {
            color: #b5b5c3;
          }
          &::-webkit-input-placeholder {
            color: #b5b5c3;
          }
        }
      }
      .cc-cvc {
        max-width: 36px;
      }
    }
  }
}

// Layout Retro
.layout-retro {
  .card_connect {
    &.form-group {
      .form-control {
        .cc-exp,
        .cc-cvc {
          input {
            font-size: 12px;
          }
        }
      }
    }
  }
}

// Layout modern
.layout-modern {
  .card_connect {
    &.form-group {
      .form-control {
        .cc-exp,
        .cc-cvc {
          input {
            color: #fff;
          }
        }
      }
    }
  }
}


/* end, : Processor Card Connect */
