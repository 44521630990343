//
// react-collapsible
//

.react-collapsible { // so far using in the Create Partner in the Admin
  .Collapsible {
    &.open .Collapsible__contentOuter {
      overflow: visible !important;
    }
    .kt-portlet__head{
      cursor: pointer;
    }

    .svg-icon svg {
      -webkit-transition: all 0.15s ease;
      transition: all 0.15s ease;
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }

    .collapse {
      .svg-icon svg {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
  }
}


.Collapsible{

  //varation-1
  &.varation-1{ // so far using in the dashboard, /settings/payment-attempts
    margin-top: 8px;
    &:first-child{
      margin-top: 0;
    }
    .Collapsible__trigger{
      padding: 16px 36px 16px 16px;
      border-radius: 4px;
      position: relative;
      z-index: 0;
      display: flex;
      font-size: 13px;
      font-weight: 500;
      color: #212121;
      cursor: pointer;
      background: rgba(77, 89, 149, 0.06);
      &::after{
        content: "";
        position: absolute;
        width: 23px;
        height: 23px;
        top:calc(50% - 11.5px);
        right:16px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24px' height='24px' viewBox='0 0 24 24' version='1.1' class='kt-svg-icon'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon points='0 0 24 0 24 24 0 24'%3E%3C/polygon%3E%3Cpath d='M12.2928955 6.70710318 C11.9023712 6.31657888 11.9023712 5.68341391 12.2928955 5.29288961 C12.6834198 4.90236532 13.3165848 4.90236532 13.7071091 5.29288961 L19.7071091 11.2928896 C20.085688 11.6714686 20.0989336 12.281055 19.7371564 12.675721 L14.2371564 18.675721 C13.863964 19.08284 13.2313966 19.1103429 12.8242777 18.7371505 C12.4171587 18.3639581 12.3896557 17.7313908 12.7628481 17.3242718 L17.6158645 12.0300721 L12.2928955 6.70710318 Z' fill='%235d78ff' fill-rule='nonzero'%3E%3C/path%3E%3Cpath d='M3.70710678 15.7071068 C3.31658249 16.0976311 2.68341751 16.0976311 2.29289322 15.7071068 C1.90236893 15.3165825 1.90236893 14.6834175 2.29289322 14.2928932 L8.29289322 8.29289322 C8.67147216 7.91431428 9.28105859 7.90106866 9.67572463 8.26284586 L15.6757246 13.7628459 C16.0828436 14.1360383 16.1103465 14.7686056 15.7371541 15.1757246 C15.3639617 15.5828436 14.7313944 15.6103465 14.3242754 15.2371541 L9.03007575 10.3841378 L3.70710678 15.7071068 Z' fill='%235d78ff' fill-rule='nonzero' opacity='0.3' transform='translate(9.000003  11.999999) rotate(-270.000000) translate(-9.000003  -11.999999) '%3E%3C/path%3E%3C/g%3E%3C/svg%3E") center no-repeat;
      }
      &:hover,
      &.is-open{
        color: #007bff;
      }
      &.is-open{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        &::after{
          transform: rotate(90deg);
          transition: all 0.3s;
        }
      }
    }
    .Collapsible__contentInner{
      padding: 16px;
      border: 1px solid #ebedf2;
    }
  }
  &.varation-2{ // so far using in the dashboard, react-ui\src\pages\components\EasyMode.js
    margin-top: 8px;
    &:first-child{
      margin-top: 0;
    }
    .Collapsible__trigger{
      padding: 13px 13px 13px 0;
      position: relative;
      z-index: 0;
      display: flex;
      font-size: 15.3px;
      font-weight: 500;
      color: #212121;
      cursor: pointer;
      border-bottom: 2px solid #F0EEF0;
      &::after{
        content: "";
        position: absolute;
        width: 23px;
        height: 23px;
        top:calc(50% - 11.5px);
        right:3px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24px' height='24px' viewBox='0 0 24 24' version='1.1' class='kt-svg-icon'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon points='0 0 24 0 24 24 0 24'%3E%3C/polygon%3E%3Cpath d='M12.2928955 6.70710318 C11.9023712 6.31657888 11.9023712 5.68341391 12.2928955 5.29288961 C12.6834198 4.90236532 13.3165848 4.90236532 13.7071091 5.29288961 L19.7071091 11.2928896 C20.085688 11.6714686 20.0989336 12.281055 19.7371564 12.675721 L14.2371564 18.675721 C13.863964 19.08284 13.2313966 19.1103429 12.8242777 18.7371505 C12.4171587 18.3639581 12.3896557 17.7313908 12.7628481 17.3242718 L17.6158645 12.0300721 L12.2928955 6.70710318 Z' fill='%235d78ff' fill-rule='nonzero'%3E%3C/path%3E%3Cpath d='M3.70710678 15.7071068 C3.31658249 16.0976311 2.68341751 16.0976311 2.29289322 15.7071068 C1.90236893 15.3165825 1.90236893 14.6834175 2.29289322 14.2928932 L8.29289322 8.29289322 C8.67147216 7.91431428 9.28105859 7.90106866 9.67572463 8.26284586 L15.6757246 13.7628459 C16.0828436 14.1360383 16.1103465 14.7686056 15.7371541 15.1757246 C15.3639617 15.5828436 14.7313944 15.6103465 14.3242754 15.2371541 L9.03007575 10.3841378 L3.70710678 15.7071068 Z' fill='%235d78ff' fill-rule='nonzero' opacity='0.3' transform='translate(9.000003  11.999999) rotate(-270.000000) translate(-9.000003  -11.999999) '%3E%3C/path%3E%3C/g%3E%3C/svg%3E") center no-repeat;
      }
      &.is-open{
        border-bottom: 2px solid #fff;
        &::after{
          transform: rotate(90deg);
          transition: all 0.3s;
        }
      }
    }
    .Collapsible__contentInner{
      padding: 0 0 16px 0;
      border-bottom: 2px solid #F0EEF0;
    }
  }
  
}
