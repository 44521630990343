/* Settings Page */
.wizard-step {
  cursor: pointer;
}

/* Payment-01 Page */
.input-group.with-prepend .rounded-lg {
  border-top-left-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
}
.input-group.with-append .rounded-lg {
  border-top-right-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
}
.input-group-payment-form {
  border: none;
}

.center-vertical-horizontal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.center-vertical-horizontal a {
  display: block;
  margin: 0 auto;
}
.payment-aside {
  position: relative;
  height: 100%
}
.center-horizontal-bottom {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -90%);
  width: 100%
}

.display-inline {
  display: inline-block!important;
}

@media (max-width: 1030px) {
  .center-vertical-horizontal {
    position: inherit;
  }
  .hide-mobile {
    display: none!important;
  }
}
@media (min-width: 1030px) {
  .hide-desktop {
    display: none;
  }
}

/* Payment-02 Page */
.input-group.with-prepend .rounded-pill {
  border-top-left-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
}
.input-group.with-append .rounded-pill {
  border-top-right-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
}
.input-group-payment-form {
  border: none;
}
.input-group-prepend.input-group-pill .input-group-payment-form {
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
}
.input-group-append.input-group-pill .input-group-payment-form {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}

.jp-card .jp-card-front, .jp-card .jp-card-back {
  background: url('../media/card-bg.jpg')!important;
  background-size: cover!important;
}


/* Requests */
#payment-requests table {
  overflow-x: auto;
}
.datatable.datatable-default > .datatable-pager > .datatable-pager-nav > li > .datatable-pager-link {
  border-radius: 100%;
}

.splink-layout-box {
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover!important;
}

/* Custom Data Capture Form */

.btn-primary-active {
    color: #FFFFFF!important;
    background-color: #187DE4!important;
    border-color: #187DE4!important;
}

.btn-primary-active i {
    color: #FFFFFF!important;
}

.hidden {
  display: none!important;
}

.custom-cb-1 .checkbox {
    padding-left: 40px;
    padding-top: 5px;
}

/* Shop Form */
.shop-image-link-1 {
  border-radius: 20px
}
@media (max-width: 800px) {
  .shop-image-link-1 {
    display: block;
    margin: 5px auto;
    border-radius: 20px
  }
}

.carousel-cell {
  width: 66%;
  height: 160px;
  margin-right: 10px;
  border-radius: 5px;
  counter-increment: carousel-cell;
  margin-top: 10px;
}

/* cell number */
.carousel-cell:before {
  display: block;
  text-align: center;
  line-height: 200px;
  font-size: 80px;
  color: white;
}
.product-card {
  background: none;
}
.product-card a {
  margin: 0;
}

.flickity-page-dots .dot.is-selected {
  background: #FFF !important;
}

.selected-product {
    height: 170px;
    margin-top: 0px;
}

.selected-product .card-body {
  border: 2px solid #3699FF;
}
.select-custom .bootstrap-select > .dropdown-toggle.btn-light {
  background: none!important;
  border-radius: 50rem!important;
  height: 40px!important;
}
.select-custom .bootstrap-select {
  padding: 0px!important;
  color: #FFF;
}

.select-custom .bootstrap-select > .dropdown-toggle:after {
  color: #FFF;
  font-size: 15px;
  padding-right: 7px;
}

.select-custom .bootstrap-select > .dropdown-toggle.btn-light .filter-option {
  color: #FFF;
}

.select-custom-filled .bootstrap-select > .dropdown-toggle.btn-light {
  background: none!important;
  border-radius: 5rem!important;
  height: 40px!important;
  border: none;
}

.select-custom-filled .bootstrap-select {
  padding: 0px!important;
}

.select-custom-filled .bootstrap-select > .dropdown-toggle:after {
  font-size: 15px;
  padding-right: 7px;
}

.select-custom-filled .filter-option-inner-inner {
  padding-top: 3px;
}

/* Start TABLE Styles */
.ReactTable {
  border: none;
}

.Table__pagination .switchPageButton:hover,
.Table__pageButton:hover,
.Table__pageButton--active {
  background: #3699FF
}

.rt-td {
  color: #6c7293;
  padding: 0;
}

.rt-tr-group:hover {
  background-color: #fafbfc;
}

.-sort-desc,
.-sort-asc {
  box-shadow: none !important;
}

.rt-thead .rt-resizable-header-content {
  color: #B5B5C3 !important;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.rt-thead .-sort-asc .rt-resizable-header-content,
.rt-thead .-sort-desc .rt-resizable-header-content {
  color: #3699FF !important;
}

.rt-thead .rt-th {
  padding: 0.75rem 1rem !important;
}

.-sort-asc .rt-resizer::before {
  content: "\2191";
  color: #3699FF;
  vertical-align: middle;
  font-weight: 600;
}

.-sort-desc .rt-resizer::after {
  content:"\2193";
  color: #3699FF;
  vertical-align: middle;
  font-weight: 600;
}

.ReactTable .rt-resizer {
  top: 5px;
}

.ReactTable .rt-td {
  align-self: center;
}

.Table__page .select-page:hover {
  background-color: #3699FF;
}

.rt-resizable-header-content {
  color: #595d6e !important;
  font-weight: 500;
}

.ReactTable .rt-expander:after {
  border-top: 7px solid #3699FF !important;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  border: 0 !important;
}

.ReactTable .rt-thead.-header {
  padding-bottom: 0 !important;
}

.ReactTable .rt-tr{
  padding-top: unset !important;
  padding-bottom: unset !important;
}

.ReactTable .rt-tbody .rt-td {
  border-right: unset !important;
}

.ReactTable .switchPageButton,
.ReactTable .Table__pageButton {
  border-radius: 50% !important;
  font-size: unset !important;
}
/* End TABLE Styles */

.react-tel-input .form-control {
  width: 100% !important;
}

.react-share__ShareButton {
  padding-right: 5px !important;
}

.invalid-row,
.invalid-row:hover {
  background-color: red !important;
}

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 100% 800px;
  display: block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

span.shine:empty:before {
  content: "\200b";
}

.shine-overlay {
  position: absolute;
  top:0;left:0;right:0;bottom:0;
  z-index: 2;
}

.input-group.input-group-solid .input-group-prepend,
.input-group.input-group-solid .input-group-append,
.input-group.input-group-solid .input-group-addon,
.input-group.input-group-solid .input-group-text {
  background-color: transparent;
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.input-group-prepend-bg-dark-error span {
  background: #fa6160;
  color: white;
}

.input-group-prepend-bg-green span {
  background: #37DB70;
  color: white;
}

.kt-aside--minimize .side-margin-20 {
  margin-left: 4px;
  margin-right: 4px;
  padding-left:2px !important;
  padding-right:2px !important;
}


.choose-image{
  position: relative;
  z-index: 0;
}
.choose-image::after{
  background-image:url(../media/bg/step-02.png);
  content: " ";
  z-index: 1;
}
.choose-image .bg-text {
  background-color: rgb(255,255,255); /* Fallback color */
  background-color: rgba(255,255,255, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
.choose-image::after,
.choose-image .uploaded-image{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top:0;left:0;right:0;bottom:0;
}
.choose-image .uploaded-image{
  z-index: 3;
}
.choose-image-overlay {
  position: absolute;
  top:0;left:0;right:0;bottom:0;
  background-color: rgba(0,0,0,0.3);
  z-index: 2;
}

/* starts, login-1 page fixed login-aside */
.login-1{}
@media (min-width: 992px){
  .login-1 .login-aside{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
  }
}
@media (min-width: 1025px) {
  .login-1{
    padding-left: 600px;
  }
}
@media (max-width: 1399.98px) and (min-width: 992px){
  .login-1{
    padding-left: 450px;
  }
}

.form-control-lg.form-control-solid {
  border: 1px solid #E4E6EF;
  background-color: #F3F6F9 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
/* end, login-1 page fixed login-aside */

/* starts, Phone Input */
.phone-input {
  padding-left: 60px !important;
  width: 93%;
}
/* ends, Phone Input */

#input-address {
  width: 100%;
}

.google-suggestion-item {
  cursor: pointer;
  padding: 5px;
  padding-left: 8px;
  font-size: 12px;
  margin: 0px;
}

.google-suggestion-item:hover {
  background-color: #f5f5f5;
}

.google-suggestion-list {
  box-shadow: 0 0.4rem 0.5rem 0.0625rem #dbdbdc !important;
}

.google-suggestion-item-manual {
  color: #FA6160;
  text-decoration: underline;
}

.modal-70 {
  width: 70% !important;
  max-width: 70% !important;
}

.image-search-thumbnail {
  width: 100%;
  height: 150px;
  object-fit: cover;
  margin-bottom: 4px;
}
.image-search-thumbnail-text {
  white-space:nowrap;
  overflow:hidden;
  padding-left: 4px;
  font-weight: 200;
  font-size: 12px;
}

.Toastify__toast--success {
  background-color: #3699FF !important;
  text-align: center;
}

.Toastify__toast--error {
  background-color: #fa6160 !important;
  text-align: center;
}

.sub-modal {
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.15s linear;
}

.cropper-modal {
  background-color: #f1f1f1;
}

.kt-avatar__holder.bg-pos{
  background-position: 50% 50% !important;
}

.custom-async-select__container{
  width: 100% !important;
  min-width: 100% !important;
  background-color: #F3F6F9 !important;
  border: 1px solid #E5EAEE !important;
  color: #3F4254 !important;
  border-radius: 0.42rem !important;
  font-size: 1rem !important;
}

.custom-async-select__control{
  background-color: unset !important;
  border-color: unset !important;
  border-radius: unset !important;
  border-style: unset !important;
}

.custom-async-select__menu{
  max-width: 100% !important;
}

.react-tel-input .form-control{
  background: #F3F6F9 !important;
}

.flatpickr-wrapper{
  width: 100%;
}

.dueDate{
  width: 100px;
}

.pull-right{
  float: right;
}

/* starts, c-selectbox styling */
.c-selectbox{
  padding-left: 0 !important;
}
.c-selectbox div[class$="-control"] {
  border: 0;
  box-shadow: none;
  padding-left: 18px;
}

.c-selectbox{
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 0.42rem !important;
}
.c-selectbox div[class$="-control"] {
  background: transparent;
  border: 0;
  box-shadow: none;
  padding-left: 3px;
}
.c-selectbox div[class$="-ValueContainer"] {
  padding: 0 10px !important;
}
.c-selectbox div[class$="-placeholder"] {
  color:#B5B5C3;
}

.login-form .c-selectbox{
  padding-bottom: 0.65rem !important;
  padding-top: 0.65rem !important;
  border-radius: 0.85rem !important;
}
.login-form .c-selectbox div[class$="-control"] {
  padding: 6px;
}
/* end, c-selectbox styling */

/* end, c-selectbox styling */

/* starts, c-branding-settings */
.c-branding-settings .background-image .image-input-wrapper{
  background-position: center;
}
/* end, c-branding-settings */

.nav-link.invalid:hover,
.nav.nav-tabs.nav-tabs-line .nav-link.invalid.active{
  border-bottom: 1px solid #fa6160 !important;
  color: #fa6160 !important;
}

.nav-link.invalid{
  color: #fa6160 !important;
}

.ReactTable .rt-tbody {
  min-height: 50px;
}

.ReactTable .rt-noData {
  top: 80px !important;
}
.error-outline {
  border: 1px #fa6160 solid !important;
}
/* starts, color-picker */
.color-picker .color{
  position: relative;
  z-index: 0;
}
.color-picker .color div.selected::before {
  position: absolute;
  top: 13.5px;
  left: 13px;
  color: white;
  font-size: 1.5em;
}
/* starts, color-picker */

.async-select-paginate div {
    border: 0;
}

.async-select-paginate:first-child,
.async-select-paginate > div:first-child {
    background-color: #f3f6f9!important;
}

/* starts connect form */
p.splink-charges-disabled {
  color: #fa1f60
}

p.splink-charges-enabled {
  color: #faaa1f
}

p.splink-charges-verified {
  color: #37db70
}

.kt-heading.kt-heading--md {
    font-size: 1.4rem;
}
.kt-heading {
    font-size: 1.3rem;
    font-weight: 500;
    padding: 0;
    margin: 1.75rem 0;
    color: #48465b;
}

.kt-heading.kt-heading--md {
    font-size: 1.4rem;
}
.kt-heading {
    font-size: 1.3rem;
    font-weight: 500;
    padding: 0;
    margin: 1.75rem 0;
    color: #48465b;
}

.kt-checkbox-list .kt-checkbox:last-child {
    margin-bottom: 5px;
}
.kt-checkbox-list .kt-checkbox {
    text-align: left;
    display: block;
}
.kt-checkbox {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    text-align: left;
    cursor: pointer;
    font-size: 1rem;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
/* ends connect form */

/* starts, form payment-form classes */

.form.payment-form {}

.form.payment-form .form-control{
  background-color: #F3F6F9;
  border-color: #F3F6F9;
  color: #464E5F;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.form.payment-form .form-control::-moz-placeholder {
  color: #B5B5C3;
  opacity: 1;
}

.form.payment-form .form-control:-ms-input-placeholder {
  color: #B5B5C3;
}

.form.payment-form .form-control::-webkit-input-placeholder {
  color: #B5B5C3;
}

.form.payment-form .form-control:active,
.form.payment-form .form-control.active,
.form.payment-form .form-control:focus,
.form.payment-form .form-control.focus {
  background-color: #ECF0F3;
  border-color: #ECF0F3;
  color: #464E5F;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}
.form.payment-form .input-group-text {
  background-color: #EBEFF2;
}
.form.payment-form .input-group-prepend.input-group-pill .input-group-payment-form{
  border-top-left-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem;
}
.input-group-append.input-group-pill .input-group-payment-form {
  border-top-right-radius: 0.42rem;
  border-bottom-right-radius: 0.42rem;
}
.form.payment-form .input-group.validation-error{
  border-color:#fa6160;
}
.form.payment-form .validation-error .c-selectbox .css-1okebmr-indicatorSeparator{
  background-color:#fa6160;
}
.form.payment-form .input-group.validation-error .form-control,
.form.payment-form .validation-error-message,
.form.payment-form .validation-error .c-selectbox div[class$="-placeholder"],
.form.payment-form .validation-error .c-selectbox .css-tlfecz-indicatorContainer{
  color:#fa6160 !important;
}
.form.payment-form .input-group.validation-error .svg-icon svg g [fill]{
  fill: #fa6160 !important;
}
.form.payment-form .input-group.validation-error .form-control::-moz-placeholder {
  color:#fa6160;
}
.form.payment-form .input-group.validation-error .form-control:-ms-input-placeholder {
  color: #fa6160;
}
.form.payment-form .input-group.validation-error .form-control::-webkit-input-placeholder {
  color:#fa6160;
}
.form.payment-form .input-group .svg-icon svg g [fill] {
  fill: #B5B5C3 !important;
}
.form.payment-form:not(.payment-form-loading) .btn-paynow .loading{
  display: none;
}
.form.payment-form.payment-form-loading:after{
  position: absolute;
  background-color: rgba(0,0,0,0);
  top:0;left:0;right:0;bottom:0;
  content: "";
}
.form.payment-form .btn.btn-primary{
  padding: 10px 6px 10px 43.5px;
}
/* end, form payment-form classes */


/* starts, success Modal  */
.swal2-icon.swal2-success [class^=swal2-success-line]{
  background-color:rgb(106, 168, 79);
}
.swal2-icon.swal2-success .swal2-success-ring{
  border-color:rgb(106, 168, 79, 0.3);
}
.swal2-icon.swal2-success .swal2-success-circular-line-left,
.swal2-icon.swal2-success .swal2-success-fix,
.swal2-icon.swal2-success .swal2-success-circular-line-right{
  background-color: rgb(255, 255, 255);
}
/* end, success Modal  */

.c-separator hr{
  border-top: 3px dashed rgba(0, 0, 0, 0.1);
}

/* starts, aside menu  */
.aside-minimize .aside-menu .desktop-buttons{
  display: none;
}
.aside-minimize .aside-menu .mobile-buttons{
  display: block;
}
.aside-minimize .aside-menu .mobile-buttons .kt-menu__item{
  text-align: center;
}
.aside-menu .mobile-buttons{
  display: none;
}
@media (min-width: 992px){
  .aside-fixed .aside{
    overflow: auto;
  }
}
/* end, aside menu  */

.disable-view {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #FFF;
  z-index: 10;
  background-color:rgba(255,255,255,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Poppins, Helvetica, sans-serif;
  text-align: center;
  font-size: 28px;
  color: #333;
  font-weight: 600;
}

.disable-view .logo-svg{
	width: 240px;
  height: 240px;
  top:31px;
  position: relative;
  overflow: hidden;
}


.payment-form-loading-overlay {
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.acs-method-form {
  width: 0 !important;
  height: 0 !important;
  margin-bottom: -6px;
  overflow: hidden;
}

/* starts, google-suggestion-list */
.google-suggestion-list{
  left:0;right:0;
  background-color: white;
  box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  color: black;
}
.google-suggestion-list .google-suggestion-item{
  cursor: default;
  padding: 8px 12px;
  margin-bottom: 0;
}
.google-suggestion-list .google-suggestion-item:hover{
  background-color: #F5F5F5;
}
.google-suggestion-list .google-suggestion-item.google-suggestion-item-manual{
  color: #fa6160;
  text-decoration: underline;
  cursor: pointer;
}
/* end, google-suggestion-list */

.apaylo-bank-details input {
  outline: none;
  color: #464E5F;
}

.apaylo-bank-details input::placeholder {
  color: #B5B5C3;
}

#other-payment-methods-form-footer,
#other-payment-methods-form-middle,
#alternate-payment-methods {
  display: none
}

.apaylo-bank-details .institution-number input,
.apaylo-bank-details .transit-number input,
.apaylo-bank-details .account-number input {
  background-color: transparent;
}


.form-tsys input,
.form-tsys input:focus {
  border: none;
  outline: none;
  background-color: transparent;
  max-width: 100%;
  color: #464E5F;
  padding: 0px;
}

.form-tsys #tsep-cardHolderName {
  width: 100%
}

.form-tsys input::placeholder {
  color: #B5B5C3;
}

.form-tsys input:not([type]) {
  display: none;
}

#tsep-cardNum {
  width: 100%
}

.tooltip-password{

}

/* starts, alert */
.alert-button button {
  margin-top: 8px;
  background: transparent;
  color: white;
  border: 1px solid white;
  padding: 6px 20px;
}

.alert-button button:hover {
  background-color: white;
  color: #ffb822
}
/* end, alert */

#boi-payment-form,
#boi-payment-form iframe {
  min-height: 358px;
}

.payment-instrument-indentifier {
  cursor: pointer;
}

.token-bank-account {
  color: #464E5F;
}

.token-bank-account:hover {
  color: white;
}

.token-bank-logo {
  width: 50px;
  height: 50px;
  object-fit: contain;
  padding: 2px;
  border-width: 1px;
  border-radius: 25px;
  border-color: #e5e5e5;
  border-style: solid;
  overflow: hidden;
  background-color: white;
}
