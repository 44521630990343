// Card

.form-emerchantpay {
  .form-control {
    padding-top: 12px;
  }
  &.format-dashboard {
    .form-control {
      display: flex;
      align-items: center;
    }
  }
}

// Layout classic
.layout-classic {
  .form-emerchantpay {
    .form-control {
      padding-top: 25.5px !important;
      padding-bottom: 23.5px !important;
    }
  }
}

// Layout modern
.layout-modern {
  .form-emerchantpay {
    .form-control {
      padding-top: 17px !important;
      padding-bottom: 14.5px !important;
      input {
        color: #ffffff;
        &:focus {
          color: #ffffff;
        }
      }
    }
  }
}
