// Card

.react-stripe {
  .form-control {
    padding-top: 12px;
  }
  &.format-dashboard{
    .form-control{
      padding-top: 15px;
    }
  }
}

// Layout classic
.layout-classic {
  .react-stripe {
    .form-control {
      padding-top: 24.715px !important;
      padding-bottom: 24.715px !important;
    }
  }
}

// Layout modern
.layout-modern {
  .react-stripe {
    .form-control {
      padding-top: 14.965px !important;
      padding-bottom: 14.965px !important;
    }
  }
}