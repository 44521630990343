// payment-link-style


.payment-link-style {

  .choose {

    .graphic {

      position: relative;
      z-index: 0;
      cursor: pointer;
      margin-bottom: 32px;

      .image {
        img {
          border-radius: 8px;
          border: 5px solid #ecf0f3;
        }
      }

      .text {
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 1.1rem;
      }

      &:hover {
        .image {
          position: relative;
          z-index: 0;


          &::before {
            position: absolute;
            z-index: 1;
            background-color: rgba($color: #000000, $alpha: 0.1);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            content: "";
            border-radius: 6px;
          }
        }
      }

      &.selected {
        &::before {
          position: absolute;
          font-size: 19.5px;
          color: #fff;
          bottom: -2px;
          right: 0px;
          z-index: 1;
        }

        &::after {
          position: absolute;
          bottom: -6px;
          right: -6px;
          background-color: var(--website-primary-color);
          content: "";
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }

        .image {
          img {
            border-color: var(--website-primary-color);
          }
        }
      }
      
    }
  }

}