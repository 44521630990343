// boi-card-modal

// default
&.boi-card-modal {
  
  .modal-dialog{
    max-width: 500px;
  }
  .modal-content {
    background-color: #E1E1E1;
    border-radius: 20px;
  }
  @media (max-width: $md-b) {
    transition: all 0.4s ease-in-out !important;
    bottom: -40vh;
    &.show {
      bottom: 0;
      top: auto;
      height: auto;
    }
    .modal-dialog{
      margin: 0 auto;
      .modal-content {
        border-radius: 0;
        .modal-body{
          padding: 16px;
        }
      }
    }
  }

}