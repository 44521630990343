// AIB

.components {
  &.payment {
    &.aib {
      iframe {
        height: 20px !important;
      }
      &.payment-methods {
        .data{
          max-height: 20px;
          position: relative;
          top:1px;
          
          input{
            background-color: transparent;
            color: #464e5f;
            font-size: 12px;
            font-family: Poppins, Helvetica, "sans-serif";

            &:focus{
              outline: none;
              color: #464e5f;
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover, 
            &:-webkit-autofill:focus{
              -webkit-box-shadow: '0 0 0 50px white inset';
            }
            
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: #B5B5C3;
              opacity: 1; /* Firefox */
            }
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: #B5B5C3;
            }
            &::-ms-input-placeholder { /* Microsoft Edge */
              color: #B5B5C3;
            }
            

          }
        }
      }
    }
  }
}

// layout-modern
.layout-modern{
  .components {
    &.payment {
      &.aib {
        &.payment-methods {
          .data{
            input{
              color: #ffffff;
              font-size: 13px;
              font-family: Arial, Helvetica, sans-serif;
              &:focus{
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}