//
// Product Modal
//

.c-modal{
  &.product{
    .image-input-wrapper{
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}