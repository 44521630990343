//
// Account Payment
//

.c-modal {
    &.account-payment {
        .form {
            .c-separator {
                display: none;
            }
        }
    }
}
