//
// pages
// shop

$border-radius-16: 16px;
$box-size: 198px;

@mixin bgImg($size: $box-size) {
  height: $size;
  //min-width: $size;
  max-width: $size;
  border-radius: $border-radius-16;
  background: url() no-repeat 50%;
  background-size: cover;
  display: block;
  margin: 0 auto;
}

.page{
  &.shop{

    // products
    .product{
      .img{
        padding-bottom: 5px;
        span {
          @include bgImg;
        }
      }
    }

  }
}
