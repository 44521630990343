// Bank Input

.apaylo {
  &.bank-input {
    &.form-group {
      .form-control {
        padding-top: 8px;
        padding-bottom: 6px;
        line-height: 1;
        input {
          outline: none;
          color: #464E5F;
          background-color: transparent;
          &::placeholder {
            color: #B5B5C3;
          }
        }
      }
      &.format-dashboard{
        .form-control{
          padding-top: 11px;
        }
      }
    }
    &.horizontal {
      .form-control {
        @media (max-width: $md-b) {
          padding-right: 10px !important;
          .transit-number,
          .account-number {
            padding-left: 6px;
          }
        }
      }
    }
  }
}

// layout-modern
.layout-modern{
  .apaylo {
    &.bank-input {
      &.form-group {
        .form-control {
          input {
            color: #ffffff;
          }
        }
      }
    }
  }
}
