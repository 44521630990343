// modal

.modal {

  // Boipa-card-modal
  @import "./boipa-card-modal";

  .modal-body{
    padding: 22px;
  }

  // layout-retro
  &.layout-retro {
    &:not(.cash-discount-modal):not(.boi-card-modal) {
      .modal-content {
        background-color: #E1E1E1;
        border-radius: 20px;
      }
    }
  }

  // layout-modern
  &.layout-modern {
    &:not(.cash-discount-modal):not(.boi-card-modal) {
      .modal-content {
        background-color: #333333;
        border: 1px solid rgba($color: white, $alpha: 0.2);
        border-radius: 20px;
      }
    }
  }

  // layout-dashboard
  &.layout-dashboard{
    .modal-content {
      border-radius: 5.46px;
    }
  }

}