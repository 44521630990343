/* starts, : Processor Card Connect specific to the Splink Dashboard */

body {
    &.splink {
        &.dashboard {
            .card_connect {
                &.form-group {
                  .form-control {
                    padding-top: 13px;
                    .cc-exp,
                    .cc-cvc {
                      max-width: 70px;
                    }
                    .cc-cvc {
                      max-width: 56px;
                    }
                  }
                }
              }
        }
    }
}

/* end, : Processor Card Connect */
