//
// Common classes
//

.max-w-80per {
  max-width: 80% !important; 
}

// *:focus{
//   outline: 0.5px solid var(--website-primary-color) !important;
// }