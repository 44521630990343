// tsys

// default
.components {
  &.payment {
    &.tsys {
      &.payment-methods {
        
        // Starts : Form
        .form-tsys{
          .form-control{
            input,
            input:focus {
              border: none;
              outline: none;
              background-color: transparent;
              max-width: 100%;
              padding: 0px;
            }
            input{
              color: #464E5F;
              &:focus{
                color: #464E5F;  
              }
              &::placeholder {
                color: #B5B5C3;
              }
              &:not([type]) {
                display: none;
              }
              &#tsep-cardNum {
                width: 100%;
              }
            }
          }
          &.format-dashboard{
            .form-control{
              display: flex;
              align-items: center;
            }
          }
        }
        // End : Form

      }
    }
  }
}

// layout-modern
.layout-modern{
  .components {
    &.payment {
      &.tsys {
        &.payment-methods {
          
          // Starts : Form
          .form-tsys{
            .form-control{
              input{
                color: #ffffff;
                &:focus{
                  color: #ffffff;  
                }
              }
            }
          }
          // End : Form

        }
      }
    }
  }
}

// layout-activate
.layout-activate{
  .components {
    &.payment {
      &.tsys {
        &.payment-methods {
          
          // Starts : Form
          .form-tsys{
            .form-control{
              min-height: 65px;
            }
          }
          // End : Form

        }
      }
    }
  }
}